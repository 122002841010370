import { take } from 'lodash/fp';
import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap';
import { formatCode } from '../common/location/formatCode';
import formatPath from '../common/location/formatPath';
import useLocations from '../hooks/useLocations';
import { LocationWithDetails, Stock } from '../modules/locations';
import matchArtikelToTerm, {
  highlightedFields,
} from './ArtikelSearchModal/matchArtikelToTerm';
import ParameterizedSearch from './ParameterizedSearch';
import {
  ParametersOptions,
  parseParameters,
  serializeParameters,
} from './ParameterizedSearch/model';
import QRScannerModal, { QRScannerModalRef } from './QRScannerModal';
import StockDetails from './StockDetails';
import { MakeTree, makeTreeIndex } from './Tree/common';

interface StockSearchProps {
  filter?: string | null;
  onSelectStock?: (stock: Stock | null) => void; // Added optional callback
}

const StockSearch: React.FC<StockSearchProps> = ({ onSelectStock, filter }) => {
  const rootLocations = useLocations();
  const locationIndex = React.useMemo(() => makeTreeIndex(rootLocations), [
    rootLocations,
  ]);
  const [selectedStock, setSelectedStock] = React.useState<Stock | null>(null);
  const handleSelectStock = useCallback(
    (stock: Stock | null) => {
      const newSelection =
        selectedStock?.recnum === stock?.recnum ? null : stock;
      setSelectedStock(newSelection);
      onSelectStock?.(newSelection);
    },
    [onSelectStock, selectedStock?.recnum],
  );
  const qrScannerRef = React.useRef<QRScannerModalRef>(null);

  const [search, setSearch] = React.useState<string>('');

  // FIXME: Using the change to a prop to set internal state is very cheeky, rethink component interface
  useEffect(() => {
    setSearch(filter || '');
  }, [filter]);

  const handleScanQRCode = async () => {
    const result = await qrScannerRef.current?.scanQRCode();
    if (result) {
      if (/qr\/location/.test(result)) {
        const regResult = /([0-9a-f-]{36})/.exec(result);
        if (regResult?.[1]) {
          const params = parseParameters(search);
          params.parameters.push({ name: 'location', value: regResult[1] });
          setSearch(serializeParameters(params));
          return;
        }
      }
      alert('Invalid QR code format');
    }
  };

  const options: ParametersOptions = {
    parameters: {},
    small: false,
    noInput: false,
  };

  let selectedLocations = rootLocations;

  const { parameters, rest: term } = React.useMemo(
    () => parseParameters(search),
    [search],
  );
  const locationParams = parameters.filter(param => param.name === 'location');
  if (locationParams.length) {
    selectedLocations = locationParams
      .map(locationParam => locationIndex[locationParam.value])
      .filter(Boolean);

    options.parameters.location = {
      icon: 'archive',
      options: selectedLocations,
      name: 'Location',
      getValue(location: LocationWithDetails) {
        return location.id;
      },
      getLabel: ((location: MakeTree<LocationWithDetails>) => {
        return formatCode(location).join('') || formatPath(location).join('/');
      }) as any,
      getLabelFromValue: ((id: string) => {
        const location = locationIndex[id];
        return formatCode(location).join('') || formatPath(location).join('/');
      }) as any,
    };
  }

  const allStock = React.useMemo(() => {
    let allStock: Stock[] = [];

    const traverse = (location: MakeTree<LocationWithDetails>) => {
      if (location.stock) {
        allStock = allStock.concat(location.stock);
      }
      if (location.children) {
        location.children.forEach(traverse);
      }
    };

    selectedLocations.forEach(traverse);

    if (term.trim()) {
      allStock = allStock.filter(matchArtikelToTerm(term));
    }

    return allStock;
  }, [selectedLocations, term.trim()]);

  const parametersRef = React.useRef(parameters);
  useEffect(() => {
    if (parameters !== parametersRef.current) {
      if (
        allStock.length === 1 &&
        !selectedStock &&
        parameters.find(p => p.name === 'location') &&
        !parametersRef.current.find(p => p.name === 'location')
      ) {
        handleSelectStock(allStock[0]);
      }
      parametersRef.current = parameters;
    }
  }, [allStock, parameters, handleSelectStock]);

  const highlightedFieldsForTerm = highlightedFields(term);

  return selectedStock ? (
    <StockDetails
      stock={selectedStock}
      showLocations={false}
      onBack={() => handleSelectStock(null)}
    />
  ) : (
    <>
      <FormGroup>
        <InputGroup>
          {/* <FormControl></FormControl> */}
          <ParameterizedSearch
            onChange={setSearch}
            value={search}
            options={options}
          ></ParameterizedSearch>
          <InputGroup.Button>
            <Button onClick={handleScanQRCode}>
              <i className="fa fa-qrcode"></i>
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
      <ListGroup className="stock-list">
        {take(20, allStock).map(stock => {
          const location = locationIndex[stock.location_id]!;
          const highlighted = highlightedFieldsForTerm(stock);
          return (
            <ListGroupItem
              key={`${stock.location_id}-${stock.recnum}`}
              onClick={() => handleSelectStock(stock)}
            >
              <Row>
                <Col xs={2}>{highlighted.code}</Col>
                <Col xs={7}>{highlighted.description}</Col>
                <Col xs={3}>
                  {/* TODO: centrally implement to location description logic for consistency  */}
                  {location.name_replaces_path
                    ? location.description
                    : formatCode(location).join('') ||
                      formatPath(location).join('/')}{' '}
                  <i className="pull-right fa fa-angle-right"></i>
                </Col>
              </Row>
            </ListGroupItem>
          );
        })}
        {allStock.length > 20 && (
          <ListGroupItem>
            <Row>
              <Col xs={12} className="text-center">
                <small>{allStock.length - 20} results hidden...</small>
              </Col>
            </Row>
          </ListGroupItem>
        )}
      </ListGroup>
      <QRScannerModal ref={qrScannerRef} />
    </>
  );
};

export default StockSearch;
