import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'wouter';
import MaterialListDetailsEditor from '../components/MaterialListDetailsEditor';
import { useCurrentUser } from '../modules/user';
import {
  InsertableMaterialList,
  MaterialList,
  useMaterialListStore,
} from '../stores/materialListStore';
import { mapByRecnum } from '../utils';

const MaterialListCreationContainer: React.FC = () => {
  const [, { save }] = useMaterialListStore();
  const [, go] = useLocation();
  const [error, setError] = React.useState<Error | null>(null);
  // const currentUser = useCurrentUser();
  const [materialList, setMaterialList] = useState<InsertableMaterialList>({
    // name: '',
    order_type: 'PROJECT',
    inventory_direction: 'IN',
    status: 'DRAFTED',
    klant_id: null,
    // created_by_usesr_id: currentUser.recnum,
    // created_at: new Date(),
    project_id: null,
    note: '',
  });

  const handleChange = useCallback((pml: Partial<MaterialList>) => {
    setMaterialList(ml => ({ ...ml, ...pml }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setError(null);
      const { recnum } = await save(materialList);
      go(`/inventory/lists/${recnum}`, { replace: true });
    } catch (error) {
      setError(error as any);
    }
  }, [go, materialList, save]);

  return (
    <MaterialListDetailsEditor
      error={error}
      // users={mapByRecnum([currentUser])}
      materialList={materialList}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default MaterialListCreationContainer;
